$(document).ready(function () {
    // For Mobile Menu
    $("#icon_mobilemenu").click(function () {
        $(".mobilelogin").slideToggle();
    });
    $("#closemenu").click(function () {
        $(".mobilelogin").slideToggle();
    });
    function nav() {
        if ($(window).width() > 767) {
            $(".stickymenu .sidemenu").css({ "display": "block" });
        }
        else {
            $(".stickymenu .sidemenu").css({ "display": "none" });
        }
    }
    $(window).resize(function () {
        setTimeout(function () { nav(); }, 0);
    });
});

// $(document).ready(function () {
//     $("#importantnote").hover(
//         function () {
//             $("#notefix").fadeIn();
//         },
//         function () {
//             $("#notefix").fadeOut();
//         }
//     );
// });


// $(document).ready(function () {
//     $('button#next').click(function () {
//         $(this).parent().parent().parent(".accordion-item").next(".accordion-item").addClass("is-active");
//         $(this).parent().parent().parent(".accordion-item").next(".accordion-item").children(".accordion-content").css("display", "block");
//         $(this).parent().parent().parent(".accordion-item").next(".accordion-item").children(".accordion-title").css("pointer-events", "initial");
//         $(this).parent().parent().parent(".accordion-item").next(".accordion-item").children(".editbtn").css("pointer-events", "initial");
//         return false;
//     });
// });

$(document).ready(function () {
    $('.passenger .passengerType').click(function (e) {
        $('.passenger .passengerselect').stop(true).slideToggle();
    });
    $(document).click(function (e) {
        if (!$(e.target).closest('.passenger .passengerType, .passengerselect').length) {
            $('.passenger .passengerselect').stop(true).slideUp();
        }
    });
});

// $(document).ready(function () {
//     $('#price').click(function (e) {
//         $('.pricebox .showbox').stop(true).slideToggle();
//         $('.pricebox .arrow').addClass('down');
//     });
//     $(document).click(function (e) {
//         if (!$(e.target).closest('#price, .pricebox .showbox').length) {
//             $('.pricebox .showbox').stop(true).slideUp();
//             $('.pricebox .arrow').removeClass('down').addClass('up');
//         }
//     });
// });

// $(document).ready(function () {
//     $('#stop').click(function (e) {
//         $('.stop .showbox').stop(true).slideToggle();
//         $('.stop .arrow').addClass('down');
//     });
//     $(document).click(function (e) {
//         if (!$(e.target).closest('#stop, .stop .showbox').length) {
//             $('.stop .showbox').stop(true).slideUp();
//             $('.stop .arrow').removeClass('down').addClass('up');
//         }
//     });
// });

// $(document).ready(function () {
//     $('#time').click(function (e) {
//         $('.time .showbox').stop(true).slideToggle();
//         $('.time .arrow').addClass('down');
//     });
//     $(document).click(function (e) {
//         if (!$(e.target).closest('#time, .time .showbox').length) {
//             $('.time .showbox').stop(true).slideUp();
//             $('.time .arrow').removeClass('down').addClass('up');
//         }
//     });
// });

// $(document).ready(function () {
//     $('#airlines').click(function (e) {
//         $('.airlines .showbox').stop(true).slideToggle();
//         $('.airlines .arrow').addClass('down');
//     });
//     $(document).click(function (e) {
//         if (!$(e.target).closest('#airlines, .airlines .showbox').length) {
//             $('.airlines .showbox').stop(true).slideUp();
//             $('.airlines .arrow').removeClass('down').addClass('up');
//         }
//     });
// });


// $('.shownow').on('click', function(){
//     $(".sdetails").slideToggle();
// });

//Tab Menu
// function openCity(evt, cityName) {
//     var i, tabcontent, tablinks;
//     tabcontent = document.getElementsByClassName("tabcontent");
//     for (i = 0; i < tabcontent.length; i++) {
//         tabcontent[i].style.display = "none";
//     }
//     tablinks = document.getElementsByClassName("tablinks");
//     for (i = 0; i < tablinks.length; i++) {
//         tablinks[i].className = tablinks[i].className.replace(" active", "");
//     }
//     document.getElementById(cityName).style.display = "block";
//     evt.currentTarget.className += " active";
// }

// Swap
// function swapValues(){
// 	var tmp = document.getElementById("form").value;
// 	document.getElementById("form").value = document.getElementById("to").value;
// 	document.getElementById("to").value = tmp;
// }

// Date Picker
// $(function () {
//     $('#dp3').datepicker();
//     $('#dp4').datepicker();
//     $('#dp5').datepicker();
// });

//  $(document).ready(function() {
//   var owl = $('.homeslider');
//   owl.owlCarousel({
//     items: 1,
//     loop: true,
//     margin: 5,
//     autoplay: true,
//     autoplayTimeout: 3000,
//     autoplayHoverPause: true,
//   });
// })

// $(document).ready(function() {
//   var owl = $('.carour2');
//   owl.owlCarousel({
//     items: 5,
//     loop: true,
//     margin: 5,
//     autoplay: false,
//     autoplayTimeout: 1000,
//     autoplayHoverPause: true,
//     responsive:{
//         0:{
//             items:3
//         },
//         600:{
//             items:5
//         },
//         1000:{
//             items:5
//         }
//     }
//   });

// })

// $(document).ready(function() {
//   var owl = $('.carour3');
//   owl.owlCarousel({
//     items: 5,
//     loop: true,
//     margin: 5,
//     autoplay: false,
//     autoplayTimeout: 1000,
//     autoplayHoverPause: true,
//     responsive:{
//         0:{
//             items:2
//         },
//         600:{
//             items:3
//         },
//         1000:{
//             items:5
//         }
//     }
//   });

// })

// $(document).ready(function() {
//   var owl = $('.carour4');
//   owl.owlCarousel({
//     items: 5,
//     loop: true,
//     margin: 5,
//     autoplay: false,
//     autoplayTimeout: 1000,
//     autoplayHoverPause: true,
//     responsive:{
//         0:{
//             items:3
//         },
//         600:{
//             items:5
//         },
//         1000:{
//             items:10
//         }
//     }
//   });

// })

// datepicker
// $('.inputtext.departure').datepicker({
//     language: 'en',
//     minDate: new Date() // Now can select only dates, which goes after today
// });
// $('.inputtext.return').datepicker({
//     language: 'en',
//     minDate: new Date() // Now can select only dates, which goes after today
// });
// $('.dob').datepicker({
//     language: 'en',
//     minDate: new Date() // Now can select only dates, which goes after today
// });
// $('#datepicker').datepicker({
//     language: 'en',
//     minDate: new Date() // Now can select only dates, which goes after today
// });
// $('#datepicker1').datepicker({
//     language: 'en',
//     minDate: new Date() // Now can select only dates, which goes after today
// });


// selectbox

// var x, i, j, selElmnt, a, b, c;
// /*look for any elements with the class "custom-select":*/
// x = document.getElementsByClassName("custom-select");
// for (i = 0; i < x.length; i++) {
//   selElmnt = x[i].getElementsByTagName("select")[0];
//   /*for each element, create a new DIV that will act as the selected item:*/
//   a = document.createElement("DIV");
//   a.setAttribute("class", "select-selected");
//   a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
//   x[i].appendChild(a);
//   /*for each element, create a new DIV that will contain the option list:*/
//   b = document.createElement("DIV");
//   b.setAttribute("class", "select-items select-hide");
//   for (j = 0; j < selElmnt.length; j++) {
//     /*for each option in the original select element,
//     create a new DIV that will act as an option item:*/
//     c = document.createElement("DIV");
//     c.innerHTML = selElmnt.options[j].innerHTML;
//     c.addEventListener("click", function(e) {
//         /*when an item is clicked, update the original select box,
//         and the selected item:*/
//         var y, i, k, s, h;
//         s = this.parentNode.parentNode.getElementsByTagName("select")[0];
//         h = this.parentNode.previousSibling;
//         for (i = 0; i < s.length; i++) {
//           if (s.options[i].innerHTML == this.innerHTML) {
//             s.selectedIndex = i;
//             h.innerHTML = this.innerHTML;
//             y = this.parentNode.getElementsByClassName("same-as-selected");
//             for (k = 0; k < y.length; k++) {
//               y[k].removeAttribute("class");
//             }
//             this.setAttribute("class", "same-as-selected");
//             break;
//           }
//         }
//         h.click();
//     });
//     b.appendChild(c);
//   }
//   x[i].appendChild(b);
//   a.addEventListener("click", function(e) {
//       /*when the select box is clicked, close any other select boxes,
//       and open/close the current select box:*/
//       e.stopPropagation();
//       closeAllSelect(this);
//       this.nextSibling.classList.toggle("select-hide");
//       this.classList.toggle("select-arrow-active");
//     });
// }
// function closeAllSelect(elmnt) {
//   /*a function that will close all select boxes in the document,
//   except the current select box:*/
//   var x, y, i, arrNo = [];
//   x = document.getElementsByClassName("select-items");
//   y = document.getElementsByClassName("select-selected");
//   for (i = 0; i < y.length; i++) {
//     if (elmnt == y[i]) {
//       arrNo.push(i)
//     } else {
//       y[i].classList.remove("select-arrow-active");
//     }
//   }
//   for (i = 0; i < x.length; i++) {
//     if (arrNo.indexOf(i)) {
//       x[i].classList.add("select-hide");
//     }
//   }
// }
// /*if the user clicks anywhere outside the select box,
// then close all select boxes:*/
// document.addEventListener("click", closeAllSelect);


// ------------------------------------------------------------------------------------------------

// $(document).ready(function () {
//     $('.flidetail').click(function (e) {
//         $(this).parent().parent().nextAll('.flighttoggle').stop(true).slideToggle('fast');
//     });
//     $('.toggleclose').click(function (e) {
//         $(this).parent('.flighttoggle').slideUp('fast');
//     });
//     $(document).click(function (e) {
//         if (!$(e.target).closest('.flidetail, .flighttoggle').length) {
//             $('.flighttoggle').stop(true).slideUp('fast');
//         }
//     });
// });

// $(document).ready(function () {
//     $('.frequentpreference').click(function (e) {
//       $(this).parent().find('.frequentflyertoggle').slideToggle('fast');
//       $(this).parent().find('.frequentpreference').addClass('down');
//     });
//     $(document).click(function (e) {
//       if (!$(e.target).closest('.frequentpreference, .frequentflyertoggle').length) {
//         $('.frequentflyertoggle').stop(true).slideUp();
//         $('.frequentpreference').removeClass('down');
//       }
//     });
// });

// $(document).ready(function () {
//     $('.flightname').click(function (e) {
//       $(this).parent().find('.textenter').slideToggle('fast');
//       $(this).parent().find('.flightname').addClass('down');
//     });
//     $(document).click(function (e) {
//       if (!$(e.target).closest('.flightname, .textenter').length) {
//         $('.textenter').stop(true).slideUp();
//         $('.flightname').removeClass('down');
//       }
//     });
// });